import React from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "./Map.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoiaWx6ZXIiLCJhIjoiY2xidGdrbzN4MTBxeTNubnRxMDhkcDNtbyJ9.2reDecqlnML3gPzaU3Orkw";

const data = [
  {
    name: "Ribeira d'Ilhas",
    location: "Ericeira",
    country: "Portugal",
    coordinates: [-9.419389, 38.987972],
  },
  {
    name: "Lagide",
    location: "Peniche",
    country: "Portugal",
    coordinates: [-9.336464, 39.372013],
  },
];

class Mapp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: -9.4170802,
      lat: 38.9632411,
      zoom: 2,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/dark-v11",
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });

    data.forEach((location) => {
      console.log(location);
      // eslint-disable-next-line
      var marker = new mapboxgl.Marker()
        .setLngLat(location.coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 60 }).setHTML(
            "<h4>" + location.name + "</h4>" + location.location,
            +location.country
          )
        )
        .addTo(map);
    });
  }

  render() {
    return (
      <div>
        <div
          ref={(el) => (this.mapContainer = el)}
          style={{ width: "100%", height: "100vh" }}
        />
      </div>
    );
  }
}

export default Mapp;
