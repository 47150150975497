import React from 'react';
import './App.css';

function Home() {
  return (
    <div className="hero-container">
      <video src="/videos/video-2.mp4" autoPlay loop muted />
      <div className="heroText">
        <h2>
          Coming soon
        </h2>
      </div>
    </div>
  );
}

export default Home