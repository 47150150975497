import React from 'react'

export default function Waves (){
  return (
    <div>
      <h2>
        Coming soon
        <br />
        <a
          href="https://www.instagram.com/theproudbanana/"
          className="contact-link"
        >
          Follow us on Instagram
        </a>
      </h2>
    </div>
  );
}
