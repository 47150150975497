import React from "react";
import './App.css';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import Map from "./Map";
import Waves from "./Waves";
import Contact from "./Contact";
import Home from "./Home";


function App() {
  return (
    <BrowserRouter>
      <div className="navbar-container">
        <Navbar expand="lg" ms-3>
          <Container>
            <Navbar.Brand as={Link} to="/" className="navbar-logo">
              the proud banana
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto ms-5">
                <Nav.Link as={Link} to="/map">
                  surf spot map
                </Nav.Link>
                <Nav.Link  as={Link} to="/waves">
                  read the waves
                </Nav.Link>
                <Nav.Link as={Link} to="/contact">
                  stay in touch
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div>
          <Routes>
            <Route path="/map" element={<Map />} />
            <Route path="/waves" element={<Waves />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
